import React from 'react';
import Layout from './Layout';
import Dictionary from './partials/Dictionary';
import Footer from './partials/Footer';

export default function Home() {
  return <>
  <Layout>
      <Dictionary />
      <Footer />
  </Layout>
  </>;
}
