import React, { useRef } from 'react';

export default function BlogRow(data) {

    const descriptionRef = useRef()

    const capitalize= (str)=>{
        if(str){
            const arr = str.split(" ");
            for (var i = 0; i < arr.length; i++) {
                arr[i] = arr[i].toLowerCase();
                arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
            }
            const str2 = arr.join(" ");
            return str2
        }
    }

    const showDescription=()=>{
        const eL = descriptionRef.current;
        let blogDescs = document.querySelectorAll(".blogDescs");
        for(let i=0; i<blogDescs.length; i++){
            if(blogDescs[i]){
                if(eL == blogDescs[i]){
                    continue;
                }
                blogDescs[i].classList.add("hideBlogDescription");
                blogDescs[i].classList.remove("showBlogDescription");
            }
        }
        
        if(eL.classList.contains('hideBlogDescription')){
            eL.classList.remove("hideBlogDescription");
            eL.classList.add("showBlogDescription");
        }
        else{
            eL.classList.remove("showBlogDescription");
            eL.classList.add("hideBlogDescription");          
        }
    }

    return <>
    <div className='row'>
        <div className='col-lg-6 col-12 moneyTxt entryRow blogTitle' onClick={()=>showDescription()}>{ capitalize(data.title)}</div>
    </div>
    <div className='row'>
        <div ref={descriptionRef} className='col-lg-6 col-12 moneyTxt entryRow blogDescs hideBlogDescription'>{data.description}</div> 
    </div>
    </>;
}
