import React from 'react';
import Layout from './Layout';
import Footer from './partials/Footer';
import Sentence from './partials/Sentence';

export default function Sentences() {
  return <>
  <Layout>
      <Sentence />
      <Footer />
  </Layout>
  </>;
}
