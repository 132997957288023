import React from 'react';

export default function TenseRow(data) {
    return <>
    <div className='row'>
        <div className='col-lg-2 col-4 moneyTxt entryRow'>{data.sentence}</div>
        <div className='col-lg-2 col-4 moneyTxt entryRow'>{data.meaning}</div>
        <div className='col-lg-2 col-4 dateTxt entryRow'>{data.description}</div>
    </div>
    </>;
}
