import React from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return <>
        <div className='row fixed-bottom paddingBottom footerBottom'>
            <div className='col-lg-2 col-4'>
            <Link to="/"><button type="button" className="btn btn-danger largeBtn btnCenter">Dictionary</button></Link>
            </div>
            <div className='col-lg-2 col-4'>
            <Link to="/idioms"><button type="button" className="btn btn-warning largeBtn btnCenter">Idioms</button></Link>
            </div>
            <div className='col-lg-2 col-4'>
            <Link to="/sentence"><button type="button" className="btn btn-success largeBtn btnCenter">Sentence</button></Link>
            </div>
        </div>
  </>;
}
