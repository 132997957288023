import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './components/Home';
import Sentences from './components/Sentences';
import Login from './components/Login';
import DictEntry from './components/partials/DictEntry';
import TenseEntry from './components/partials/TenseEntry';
import Idioms from './components/Idioms';
import IdiomEntry from './components/partials/IdiomEntry';
import Blogs from './components/Blogs';
import BlogEntry from './components/partials/BlogEntry';

function App() {
  return (
    <>
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/sentence" element={<Sentences/>} />
        <Route path="/idioms" element={<Idioms />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/login" element={<Login />} />
        <Route path="/dict/add" element={<DictEntry />} />
        <Route path="/tense/add" element={<TenseEntry />} />
        <Route path="/idioms/add" element={<IdiomEntry />} />
        <Route path="/blogs/add" element={<BlogEntry />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
