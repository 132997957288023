import React from 'react';
import Layout from './Layout';
import Blog from './partials/Blog';
import Footer from './partials/Footer';

export default function Blogs() {
    return <>
    <Layout>
        <Blog />
        <Footer />
    </Layout>
    </>;
}
