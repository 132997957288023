import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Navigation() {

    const path = "/";
    const colorClass = ((path=='/credit') ? 'red' : ((path=='/debit') ? 'green' : ''));
    const store = useSelector(state=>state);
    const navigate = useNavigate();

    const logOut=()=>{
        localStorage.removeItem("usertoken");
        localStorage.removeItem("uservalue");
        toast("Logout Success", {
            position: "bottom-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            onClose: () => window.location.href="/"
        });
    }

    const openNav=()=>{
        let mySidenav = document.getElementById("mySidenav");
        if(mySidenav){
            mySidenav.style.width = "250px";
            // mySidenav.style.display = "block";
        }
    }

    const closeNav=()=>{
        let mySidenav = document.getElementById("mySidenav");
        if(mySidenav){
            mySidenav.style.width = "0";
            // mySidenav.style.display = "none";
        }
    }

  return <>
        <div className='row fixed-top marginBottom bottomGrey fixedNav'>
        
        {
            (store?.user?.id) ?
            <>
            <div id="mySidenav" className="sidenav">
                <a href={void(0)} className="closebtn" onClick={()=>closeNav()}>&times;</a>
                <Link to="/"><a>Home</a></Link>
                <Link to="/blogs"><a>Blogs</a></Link>
                <Link to="/dict/add"><a>Add Word</a></Link>
                <Link to="/tense/add"><a>Add Tense</a></Link>
                <Link to="/idioms/add"><a>Add Idiom</a></Link>
                <Link to="/blogs/add"><a>Add Blog</a></Link>
            </div>
            </>
            :
            ''
        }

            <div className='col-lg-2 col-2'>
            {
            (store?.user?.id) ?
            <>
            <span className='menuIcon' onClick={()=>openNav()}>&#9776;</span>
            </>
            :
            ''
            }
            </div>

            <div className={ 'col-lg-2 col-7 siteTitle '+ colorClass}>
                <div className={ "roundLogo "+ colorClass}><Link to="/">E</Link></div>
                <div className="titleTxt"><Link to="/">English App</Link></div>
            </div>
            {
                (store?.user?.id) &&
                <div className={ 'col-lg-2 col-2'}>
                    <div className="logoutBtn pullRight" onClick={()=>logOut()}>Logout</div>
                </div>
            }
        </div>
        <ToastContainer />
  </>;
}
