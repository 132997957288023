import React from 'react';

export default function IdiomRow(data) {
    return <>
    <div className='row'>
        <div className='col-lg-3 col-6 moneyTxt entryRow'>{data.idiom}</div>
        <div className='col-lg-3 col-6 moneyTxt entryRow'>{data.meaning}</div>
        {/* <div className='col-lg-2 col-4 dateTxt entryRow'>{data.description}</div> */}
    </div>
    </>;
}
