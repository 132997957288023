import React, {useState, useRef} from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { useAddUserToStore, validateData } from '../helpers';
import Layout from './Layout';

export default function Login() {

    const navigate = useNavigate();
    const emailRef = useRef(null);
    const passwordRef = useRef(null);
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [errors, setErrors] = useState([])
    const [apiErrors, setApiErrors] = useState([])
    const {addUserData} = useAddUserToStore();

    const Login = (e)=>{
        e.preventDefault();
        setApiErrors([]);
        setErrors([]);
        let frmData = {
            email: emailRef.current.value,
            password: passwordRef.current.value
        }
        const frmErrors = validateData(frmData);
        if(!frmErrors){
            setBtnDisabled(true);
            axios.post("/login", frmData)
            .then(response=>{
                //console.log(response);
                if(response?.data?.type){
                    setBtnDisabled(false);
                    if(response.data.type=='success'){
                        localStorage.setItem('usertoken', response.data.user.token);
                        localStorage.setItem('uservalue', btoa(response.data.user.id));
                        addUserData(response.data.user);
                        document.getElementById("employerLogin").reset();
                        navigate("/");
                    }
                    if(response.data.type=='error'){
                        setApiErrors([response.data.message]);
                    }
                }
            })
            .catch(error=>{
                console.log(error);
                setBtnDisabled(false);
            });
        }
        else{
            setErrors(frmErrors);
            setBtnDisabled(false);
        }
    }

  return <>
  <Layout>
  <div className="signup-form">
    <form id="employerLogin" action="#" method="post">
		<p className="hint-text">Login to your account.</p>
        <div className="form-group">
        	<input ref={emailRef} type="email" id="email" autoComplete='off' className="form-control" name="email" placeholder="Email"/>
            <label className="error">{errors.email}</label>
        </div>
		<div className="form-group">
            <input ref={passwordRef} type="password" id="password" className="form-control" name="password" placeholder="Password" />
            <label className="error">{errors.password}</label>
        </div> 
		<div className="form-group">
            <button type="submit" disabled={ btnDisabled ? 'disabled' : '' } className="btn btn-blue btn-lg btn-block" onClick={(e)=>Login(e)}>Login Now</button>
        </div>
        <ul className="errorList">
        {
            apiErrors.map((error,key)=><li key={key}>{error}</li>)
        }
        </ul>
    </form>
    </div>
  </Layout>
  </>;
}
