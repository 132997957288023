import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import IdiomRow from './IdiomRow';

export default function Idiom() {

    const [idiomList, setIdiomList] = useState([]);
    const [loading, setLoading] = useState(false);
    const textRef = useRef()

    useEffect(() => {
        setLoading(true)
      axios.get("/idioms/listing")
      .then(response=>{
          if(response?.data){
            let result = response.data;
            if(result.length>0){
                setIdiomList(result)
            }
            setLoading(false)
          }
          //console.log("response", response)
      })
      .catch(error=> console.log(error))
    }, []);

    const searchTense=()=>{
        setLoading(true)
        let query = textRef.current.value;
        query = query.trim()
        axios.post("/idioms/search", {query:query})
        .then(response=>{
            if(response?.data){
                let result = response.data;
                setIdiomList(result)
                setLoading(false)
                //console.log("response", result)
            }
        })
        .catch(error=> console.log(error))
    }

  return <>
        <div className='row'>
            <div className='col-lg-4 col-8 entryHead dateFilter'>
                <input type="text" ref={textRef} className='form-control' onKeyUp={()=>searchTense()} />
            </div>
            <div className='col-lg-2 col-4 entryHead'>
                <button type="button" className="btn btn-warning largeBtn btnCenter" onClick={()=>searchTense()}>Search</button>
            </div>
        </div>
        <div className='row'>
            <div className='col-lg-3 col-6 entryHead'>Idiom</div>
            <div className='col-lg-3 col-6 entryHead'>Meaning</div>
            {/* <div className='col-lg-2 col-4 entryHead'>Detail</div> */}
        </div>
        {
            (!loading) ?
            idiomList.map((data, key)=>{
                return <IdiomRow {...data} key={key}/>
            })
            :
            <Skeleton count={10} />
        }
  </>;
}
