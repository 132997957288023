import React, { useEffect } from 'react';
import Navigation from './partials/Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { addUser } from '../redux/actioncreators/UserActionCreator';
import axios from 'axios';

export default function Layout({children}) {
  
  const storeData = useSelector(state=>state)
  const dispatch = useDispatch()

  const checkLogin = ()=>{
    let token = localStorage.getItem("usertoken")
    let userId = localStorage.getItem("uservalue")
    if(token && userId){
      userId = atob(userId)
      checkIfTokenValid(token, userId)
    }
  }

  const checkIfTokenValid = (token, userId)=>{
    // console.log("token", token)
    // console.log("userId", userId)
    axios.post("/checkusertoken", {token:token, userId: userId})
    .then(response=>{
      if(response?.data){
        if(response.data.type=='success'){
          dispatch(addUser(response.data.user))
        }
        //console.log("response", response)
      }
    })
    .catch(error=>console.log(error))
  }

  useEffect(()=>{
    if(!storeData?.user?.id){
      checkLogin()
    }
  }, [])

  return <>
  <div className="container-fluid mainBodyContainer">
      <div className="col-md-12">
          <Navigation />
          <div className='startBody'>&nbsp;</div>
      </div>
      {children}
  </div>
  </>;
}
