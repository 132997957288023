import axios from 'axios';
import React, {useState, useRef} from 'react';
import Layout from '../Layout';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { validateData } from '../../helpers';
import { useNavigate } from 'react-router-dom';

export default function DictEntry() {

    const navigate = useNavigate();
    const wordRef = useRef(null);
    const meaningRef = useRef(null);
    const detailRef = useRef(null);
    const [btnDisabled, setBtnDisabled] = useState(false)
    const [apiErrors, setApiErrors] = useState([])
    const [errors, setErrors] = useState([])
    const [success, setSuccess] = useState(false)
    const colorClass = 'green';
    const entryUrl = "/add-dictionary";

    const addEntry=(e)=>{
        e.preventDefault();
        setApiErrors([]);
        setErrors([]);
        setSuccess(false);
        let token = (localStorage.getItem("usertoken")) ? localStorage.getItem("usertoken") : "";
        let userId = (localStorage.getItem("uservalue")) ? atob(localStorage.getItem("uservalue")) : "";
        let frmData = {
            'word': wordRef.current.value,
            'meaning': meaningRef.current.value,
            // 'detail': detailRef.current.value,
            'token': token,
            'userId': userId
        }
        const frmErrors = validateData(frmData);
        if(!frmErrors){
            frmData = {
                'word': wordRef.current.value,
                'meaning': meaningRef.current.value,
                'detail': detailRef.current.value,
                'token': token,
                'userId': userId
            }
            setBtnDisabled(true);
            axios.post(entryUrl, frmData)
            .then(response=>{
                //console.log(response);
                if(response?.data?.type){
                    setBtnDisabled(false);
                    if(response.data.type=='success'){
                        document.getElementById("employerLogin").reset();
                        setSuccess(response.data.message);
                        setTimeout(() => {
                            navigate("/");
                        }, 1500);
                    }
                    if(response.data.type=='error'){
                        setApiErrors([response.data.message]);
                        setBtnDisabled(false);
                    }
                }
            })
            .catch(error=>{
                console.log(error);
                setBtnDisabled(false);
            });
        }
        else{
            setBtnDisabled(false);
            setErrors(frmErrors);
        }
    }

  return <>
  <Layout>
  <div className="signup-form entry-form">
        <form id="employerLogin" action="#" method="post">
            <div className="form-group">
                <input type="text" id="word" ref={wordRef} className="form-control" name="word" placeholder="Enter Word" 
                />
                <label className="error">{errors.word}</label>
            </div>
            <div className="form-group">
                <input type="text" ref={meaningRef} id="meaning" className="form-control" name="meaning" placeholder="Enter Meaning"/>
                <label className="error">{errors.meaning}</label>
            </div>
            <div className="form-group">
                <input type="text" ref={detailRef} id="detail" className="form-control" name="detail" placeholder="Enter Detail"/>
                <label className="error">{errors.detail}</label>
            </div>
            <div className="form-group">
                <button disabled={ btnDisabled ? 'disabled' : '' } type="submit" className={ "btn btn-"+ colorClass +" btn-lg btn-block form-control" } onClick={(e)=>addEntry(e)}>Save</button>
            </div>
            <ul className="errorList">
            {
                apiErrors.map((error,key)=><li key={key}>{error}</li>)
            }
            {
                (errors?.token) &&
                <label className="error">You are not log in!!</label>
            }
            </ul>
            {
                success &&
                <div className="alert alert-info" role="alert">
                    Your record has been saved!!
                </div>
            }
        </form>
    </div>
    <ToastContainer />
  </Layout>
  </>;
}
