import React from 'react';
import Layout from './Layout';
import Footer from './partials/Footer';
import Idiom from './partials/Idiom';

export default function Idioms() {
    return <>
    <Layout>
        <Idiom />
        <Footer />
    </Layout>
    </>;
}
