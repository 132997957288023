// import axios from 'axios';
import {useSelector, useDispatch} from 'react-redux';
import { addUser } from '../redux/actioncreators/UserActionCreator';
// import { useLocation, useNavigate } from 'react-router-dom'
import {useEffect} from 'react';

export const validateData =(data)=>{
    let frmError = [];
    for(let key in data){
        if(!data[key]){
            frmError[key] = key+' is requred';
        }
    }
    if(Object.keys(frmError).length <= 0){
        return false;
    }
    return frmError;
}

export const useAddUserToStore=(user=null)=>{
    const dispatch = useDispatch();
    const addUserData = (user)=>{
        //console.log("add data to store");
        dispatch(addUser(user));
    }
    useEffect(() => {
        if(user){
            addUserData(user);
        }
    }, [])
    return {addUserData};
}

// export const useCheckUserLogin=(value=null)=>{
//     const {addUserData} = useAddDataToStore();
//     const navigate = useNavigate();
//     const location = useLocation();
//     const path = location.pathname;
//     const checkUser=()=>{
//         if(localStorage.getItem("user")){
//             axios.get('/userdetails')
//             .then(response=>{
//                 //console.log("response", response)
//                 if(response?.data?.type){
//                     if(response.data.type=='success'){
//                         addUserData(response.data.user)
//                     }
//                     else{
//                         if(path!=='/login' && path!=='/signup'){
//                             window.location.href="/login";
//                         }
//                     }
//                 }
//             })
//             .catch(error=>{
//                 if(path!=='/login' && path!=='/signup'){
//                     window.location.href="/login";
//                 }
//             });
//         }
//         else{
//             if(path!=='/login' && path!=='/signup'){
//                 window.location.href="/login";
//             }
//         }
//     }
//     useEffect(() => {
//         if(value){
//             checkUser();
//         }
//     }, []);
//     return {checkUser};
// }

// export const useAddDataToStore=(user=null)=>{
//     const dispatch = useDispatch();
//     const addUserData = (user)=>{
//         //console.log("add data to store");
//         dispatch(addUser(user));
//     }
//     useEffect(() => {
//         if(user){
//             addUserData(user);
//         }
//     }, [])
//     return {addUserData};
// }

// export const useCheckUserIfNotInStore=()=>{
//     const {checkUser} = useCheckUserLogin();
//     const store = useSelector(state=>state);
//     if(!store?.user?.id){
//         checkUser(true);
//     }
// }