import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export default function DictRow(data) {

  const storeData = useSelector(state=>state)
  const navigate = useNavigate();

  return <>
    <div className='row'>
      <div className='col-lg-3 col-6 moneyTxt entryRow'>{data.word}</div>
      <div className='col-lg-3 col-6 moneyTxt entryRow'>{data.meaning}</div>
      {/* <div className='col-lg-2 col-4 dateTxt entryRow'>{data.detail}</div> */}
    </div>
  </>;
}
