import { ADD_USER, REMOVE_USER } from "../constants";

const initialState = {

}

export const UserReducer=(state=initialState, action)=>{
    switch (action.type) {
        case ADD_USER:
            return {
                ...state,
                ...action.payload
            }
        case REMOVE_USER:
            return {}
        default:
            return state;
    }
}